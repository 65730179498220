var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',[_c('v-card-text',{attrs:{"id":"hook-edit__card-text"}},[_c('v-alert',{attrs:{"value":true,"type":"info"}},[_vm._v(" Este hook versión 1 se encuentra limitado a lógicas simples que en un futuro entrarán en desuso. ")]),_c('Fields',{attrs:{"id":"hook-edit__fields","schema":{
        name: {
          type: 'string',
          label: 'Nombre',
          description: 'Debe ser único',
          sizeSmall: 12,
          sizeLarge: 12,
          newDesign: true
        },
        functionTypeId: {
          type: 'select',
          label: 'Tipo de Función (V1)',
          fieldOptions: {
            options: _vm.functionTypes.map(function (f) { return ({ label: f.name, value: f._id }); }),
          },
          sizeSmall: 12,
          newDesign: true
        },
        validationsIds: {
          type: 'validationSelect',
          label: 'Validaciones',
          fieldOptions: {
          },
          sizeSmall: 12,
          sizeLarge: 6,
          optional: true,
          newDesign: true
        },
        validationsMode: {
          type: 'select',
          label: 'Modo de validaciones',
          fieldOptions: {
            options: [
              { label: 'Todas las validaciones deben cumplirse (AND)', value: 'AND' },
              { label: 'Al menos una validación debe cumplirse (OR)', value: 'OR' } ],
          },
          sizeSmall: 12,
          sizeLarge: 6,
          optional: true,
          newDesign: true
        },
        shouldThrow: {
          type: 'checkbox',
          label: 'Detener ejecución si el hook anterior falló',
          optional: true
        },
        ignoreErrors: {
          type: 'checkbox',
          label: 'Ignorar Errores',
          optional: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.hook),callback:function ($$v) {_vm.hook=$$v},expression:"hook"}}),(_vm.hook.functionTypeId)?[_c('h2',{staticClass:"headline font-weight-bold ml-2"},[_vm._v("Opciones adicionales de lógica")]),_c('br'),_c('OptionFields',{attrs:{"id":"hook-edit__optional-fields","schema":_vm.schema,"environmentVariables":{ environmentId: _vm.environmentId, hookId: _vm.componentId, hookOptions: _vm.hook.options },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.hook.options),callback:function ($$v) {_vm.$set(_vm.hook, "options", $$v)},expression:"hook.options"}})]:_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Hook"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }